@import "../../../styles/variable.module.scss";
.hot__inner_bids {
  border-radius: 8px;
  border: 1px solid var(--border-color);
  margin-bottom: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include medium_device{
    margin-bottom: 16px;
  }
  &:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);
    box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);
    z-index: 1;
    position: relative;
  }
  .desc {
    .card__avatar {
      width: 25px;
      height: 25px;
      border-radius: 50px;
      overflow: hidden;
      background-color: var(--background-color);
      display: inline-block;
      vertical-align: middle;
      @include medium_device{
        width: 20px;
        height: 20px;
      }
    }
    .card__title {
      padding-left: 8px;
      display: inline-flex;
      width: 80%;
      align-items: center;
      @include medium_device{
        width: 82%;
      }
      .names{
        opacity: 1;
      }
      span {
        &:first-child {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 8px;
          max-width: 88%;
          font-size: 16px;
          line-height: 24px;
          color: rgb(20 20 22 / 70%);
          @include medium_device{
            font-size: 13px;
            line-height: 20px;
            padding-right: 5px;
          }
        }
        &:last-child:not(:first-child){
          flex: 0 0 auto;
          @include medium_device{
            height: 14px !important;
            width: 14px !important;
          }
        }
      }
    }
    a {
      color: var(--variantColor);
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: var(--variantColor);
      }
    }
    .done {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-left: 8px;
    }
  }
  .moreOpts {
    position: relative;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    @include medium_device {
      opacity: 1;
      visibility: visible;
    }
    &:hover {
      .dropdownMenu {
        opacity: 1;
        visibility: visible;
        top: 100%;
      }
    }
    .NftDropdown{
      @include medium_device{
        max-width: 150px;
        min-width: 150px;
        a{
          padding: 8px;
          font-size: 12px;
        }
      }
    }
    .headerItem {
      cursor: pointer;
      &.active {
        ul {
          display: block;
        }
      }
      button {
        padding: 0;
        border: none;
        width: 32px;
        height: 32px;
        border-radius: 20px;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        @include medium_device{
          height: 22px;
          width: 22px;
          svg{
            width: 15px;
            height: auto;
          }
        }
        &:hover {
          background: var(--background-color);
        }
        &::after {
          content: none;
        }
      }
      &[class*="show"]{
        button {
          background: var(--background-color);
        }
      }
    }
  }
  .cardImage {
    border-radius: 8px 8px 0 0;
    height: auto;
    width: 100%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .icon{
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
      cursor: pointer;
      .rightIcon,
      .cicleIcon{
        display: none;
      }
    }
    .multipleNft,
    .likeNft {
      position: absolute;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      span {
        margin-right: 5px !important;
        height: 16px !important;
        width: 16px !important;
        img {
          object-fit: contain;
        }
      }
    }
    /*.likeNft {
      opacity: 0;
      visibility: hidden;
      top: 8px;
      display: flex;
      align-items: center;
      border-radius: 24px;
      padding: 2px 6px;
      color: $lightColor;
      @include fontWeight(font-weight, 500, 700);
    }
    .multipleNft {
      top: 8px;
      display: flex;
      align-items: center;
      border-radius: 24px;
      padding: 2px 6px;
      color: $lightColor;
      @include fontWeight(font-weight, 500, 700);
    }*/
    .multipleNft {
      left: 8px;
      background: rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      .CopyBidIocn{
        height: 16px;
        width: 16px;
        margin: 0 5px 0 0;
      }
    }
    .likeNft {
      right: 8px;
      background-color: $lightColor;
      color: $customFont;
      .active {
        -webkit-animation: scaleIo 0.5s linear;
        -moz-animation: scaleIo 0.5s linear;
        -o-animation: scaleIo 0.5s linear;
        animation: scaleIo 0.5s linear;
        @keyframes scaleIo {
          20% {
            transform: scale(1.1);
          }
          50% {
            transform: scale(1.2);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }
  &:hover {
    .moreOpts {
      opacity: 1;
      visibility: visible;
    }
    .cardImage {
      .likeNft {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .cardDetails {
    padding: 0 13px 10px;
    background-color: var(--light-bg);
    flex: 1 1 auto;
    @include medium_device{
      padding: 0 10px 10px;
    }
    .opts {
      & > [class*="-start"] {
        max-width: 80%;
        width: 100%;
      }
    }
  }
  .name {
    @include fontWeight(font-weight, 600, 700);
    font-size: 16px;
    line-height: 24px;
    color: var(--variantColor);
    padding: 10px 13px 0;
    background-color: var(--light-bg);
    @include medium_device{
      font-size: 14px;
      line-height: 22px;
      padding: 10px 10px 5px;
    }
    .title {
      color: var(--variantColor);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 85%;
      @include medium_device{
        width: 83%;
      }
      &:hover {
        color: var(--variantColor);
      }
    }
  }
  .card__status {
    @include fontWeight(font-weight, 500, 700);
    font-size: 14px;
    line-height: 24px;
    color: $customFont;
    padding-top: 8px;
    min-height: 49px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @include medium_device{
      padding-top: 5px;
      > div{
        flex-direction: column;
        align-items: flex-start !important;
      }
    }
    & > span {
      font-size: 13px;
      line-height: 24px;
      color: var(--light-font);
      @include medium_device{
        line-height: 20px;
      }
    }
    .priceContent {
      color: var(--variantColor);
      line-height: normal;
    }
    .text {
      color: rgb(20 20 22 / 70%);
      font-size: 14px;
      line-height: 24px;
      display: block;
      font-weight: 400;
      @include medium_device{
        font-size: 12px;
        line-height: 20px;
      }
    }
    .content {
      span {
        color: rgb(20 20 22 / 70%);
        @include fontWeight(font-weight, 400, 700);
        font-size: 14px;
        line-height: 24px;
        display: block;
        cursor: pointer;
        margin-left: 4px;
        white-space: nowrap;
        @include medium_device{
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
    .ethPrice {
      display: inline-block;
      line-height: 24px;
      color: var(--variantColor);
      font-size: 16px;
      @include fontWeight(font-weight, 500, 700);
      white-space: nowrap;
      @include medium_device{
        font-size: 15px;
        line-height: 22px;
        white-space: inherit;
      }
      & + span {
        vertical-align: middle;
      }
    }
  }
  .dPrice {
    font-size: 13px;
    color: $customFont;
    line-height: 20px;
    @include fontWeight(font-weight, 400, 700);
  }
}
[data-theme="dark"] {
  .hot__inner_bids {
    .moreOpts {
      .headerItem {
        button {
          & ~ div {
            border: 1px solid $secondaryBg;
            a{
              background: var(--background-color);
            }
          }
        }
      }
    }
    .desc{
      .card__title{
        span:first-child{
          color: $text-color;
        }
      }
    }
    .card__status{
      .text{
        color: $text-color;
      }
      .content{
        span{
          color: $text-color;
        }
      }
    }
  }
}
[dir="rtl"] {
  .hot__inner_bids {
    .card__status {
      .content {
        span {
          margin-right: 4px;
          margin-left: 0;
        }
      }
    }
    .desc {
      .card__title {
        padding-left: 0;
        padding-right: 8px;
        span {
          &:first-child {
            padding-left: 8px;
            padding-right: 0;
            @include medium_device{
              padding-left: 5px;
            }
          }
        }
      }
    }
    .info1 {
      .card__status {
        & > span {
          text-align: right;
        }
        .priceContent,
        .content {
          text-align: left;
        }
        .priceContent {
          direction: ltr;
        }
        .priceContent,
        .bid {
          .priceInfo {
            direction: ltr;
          }
        }
        .bid {
          .priceInfo {
            justify-content: flex-end;
          }
        }
        .content {
          span {
            margin-right: 4px;
            margin-left: 0;
          }
        }
        .ethPrice {
          direction: ltr;
        }
      }
      .dPrice {
        direction: ltr;
        text-align: right;
      }
    }
    .cardImage {
      .multipleNft,
      .likeNft {
        span {
          margin-left: 5px !important;
          margin-right: 0 !important;
        }
      }
      .multipleNft {
        right: 8px;
        left: auto;
        .CopyBidIocn{
          margin: 0 0 0 5px;
        }
      }
      .likeNft {
        left: 8px;
        right: auto;
      }
      .icon{
        right: auto;
        left: 10px;
      }
    }
    .moreOpts {
      .NftDropdown{
        a{
          @include medium_device{
            font-size: 11px;
          }
        }
      }
      .headerItem {
        button {
          & ~ div {
            left: 0 !important;
            right: auto !important;
          }
        }
      }
    }
    .done {
      margin-right: 4px;
      margin-left: 0;
    }
  }
}
.no_metadata_available {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  text-align: center;
  border-radius: 9px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  > div{
    display: flex;
  }
}
.content_not_availabe {
  padding-top: 10px;
  color: #212529;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.2 !important;
}
.refresh_btn {
  padding-top: 10px;
  color: #044eff;
  font-size: 14px;
  font-weight: 500;
}
.no_metadata_available_loading {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  text-align: center;
  border-radius: 9px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  p{
    font-size: 15px;
  }
  > div {
    width: 100%;
  }
  [class*="shimmer-thumbnail"]{
    min-width: inherit;
  }
}
